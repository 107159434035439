import * as Sentry from '@sentry/browser'

interface ISentryConfig {
  dsn: string
  release: string
}

declare global {
  interface IWindow {
    sentryConfig: ISentryConfig
    sentryRaiseException: CallableFunction
  }
}

declare const window: IWindow

if ('sentryConfig' in window) {
  Sentry.init(window.sentryConfig)
}

// This method is used for testing the sentry integration.
window.sentryRaiseException = () => {
  throw new Error('This is a frontend-test exception')
}
